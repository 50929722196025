<template>
  <div id="not-found">
    <Error />
  </div>
</template>

<script>
import Error from "@/components/Error";

export default {
  name: "NotFound",
  components: {
    Error
  }
};
</script>
